require('../hermes-setup');
require('../flights-setup');

global.wlAngularDeps = [
  "ngAnimate",
];

global.loadAppDeps = function () {
  loadHermesDeps();
  loadFlightsAppDeps();
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/vma/locales');
  require('../../assets/javascripts/whitelabel/vma/controllers/vma-controller');
  require('../../assets/javascripts/whitelabel/vma/services');
  require('../../assets/javascripts/whitelabel/vma/components');
}

require('../application');
