import { AdobeAnalyticsData } from "booking_app/types";

const pageNamePrefix: string = "deNovo-loyalty-hotels";

export const adobeAnalyticsData: AdobeAnalyticsData  = {
  "home": {
    pageName: `${pageNamePrefix}`,
  },
  "manage-booking": {
    pageName: `${pageNamePrefix}:manage`,
  },
  // Hotels
  "search-result": {
    pageName: `${pageNamePrefix}:search-results`,
  },
  "hotel-detail": {
    pageName: `${pageNamePrefix}:details`,
  },
  "checkout": {
    pageName: `${pageNamePrefix}:checkout`,
  },
  "confirmation": {
    pageName: `${pageNamePrefix}:confirmation`,
  },
};
