import { LinkTargetType, SortType, Tenant } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { HermesService } from "booking_app/services/hermes/hermes.service";
import { VmaAdobeAnalyticsService } from "../services/adobe-analytics.service";
import { SearchSortingService } from "booking_app/services/search-sorting.service";
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";
import { adobeAnalyticsData } from "../constants/adobe-analytics-data";
import "../constants/modal-settings";

declare var angular: any;

angular.module("BookingApp").config([
  "$routeProvider", "$injector",
  ($routeProvider, $injector) => {
    $routeProvider.when("/:travelType", {
      controller: "SearchCtrl",
      templateUrl: "/html/whitelabel/vma/search",
      resolve: {
        parseUrl: $injector.get("ParseTrafficParams"),
      },
    });
  },
]);

class VmaController {

  static $inject = [
    "$scope",
    "$rootScope",
    "$window",
    "AppSettings",
    "KaligoConfig",
    "GlobalStateService",
    "HermesService",
    "VmaAdobeAnalyticsService",
    "UserAgentChecker",
    "SearchSortingService",
  ];

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $window: any,
    private appSettings: AppSettings,
    private kaligoConfig: any,
    private globalStateService: GlobalStateService,
    private hermesService: HermesService,
    private vmaAdobeAnalyticsService: VmaAdobeAnalyticsService,
    private userAgentChecker: UserAgentChecker,
    private searchSortingService: SearchSortingService,
  ) {
    this.appSettings.tenant = Tenant.VMA;
    this.appSettings.e2eWhitelabel = true;
    this.appSettings.mobileFirst = true;
    this.appSettings.iconFormat = "svg";
    this.appSettings.dateIcon = "icon-calendar.svg";
    this.appSettings.displayNonBookableMarketRates = true;
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "vma/icons/filter.svg",
      sortIconSource: "vma/icons/sort.svg",
      mapIconSource: "vma/icons/map.svg",
    };
    this.appSettings.hotelDetailsTemplateConfig.breakfastIconUrl = "/vma/icons/breakfast.svg";
    this.appSettings.hotelDetailsTemplateConfig.noBreakfastIconUrl = "/vma/icons/nobreakfast.svg";
    this.appSettings.hotelDetailsTemplateConfig.earnOnRedeem = true;
    this.appSettings.hasCouponCode = false;
    this.appSettings.storeCreditCard = true;
    this.appSettings.logoutUrl = "/whitelabel/vma/logout";
    this.appSettings.showGulfAlert = false;
    this.appSettings.mapboxHotelMarkerColor = "#5020A0";
    this.appSettings.mapboxHotelClusterColor = "#5020A0";
    this.appSettings.mapboxHotelClusterCounterTextColor = "#FFFFFF";
    this.appSettings.defaultCurrencyDisplay = "symbol";
    this.appSettings.customResultLoadingMessage = "txt.search_result.loading.subtext.incredible_deals";
    this.appSettings.defaultCountryInCheckout = {
      hasDefaultCountry: true,
      defaultCountryCode: "AU",
    };
    this.appSettings.hasMultiplePaymentMethods = true;
    this.appSettings.openInTargetTab.allLinksTarget = LinkTargetType.SELF;
    this.appSettings.openInTargetTab.markerPopUp = LinkTargetType.SELF;
    this.appSettings.manageBooking.cancellationAlertHeaderText = "Just checking...";
    this.appSettings.useHighResolutionUrl = true;
    this.appSettings.hasCustomflightSummaryHeaderText = true;
    this.appSettings.customFlightIcon = true;
    this.appSettings.showFlightSelectionState = true;
    this.appSettings.hotelBookingDetailToggles = {
      showEmptyRoomRemarks: true,
      showRoomsLabel: true,
      showNightsLabel: true,
      showGuestsSummary: true,
    };
    this.appSettings.flightSegmentArrivalDisplay = true;
    this.appSettings.checkoutSettings.insufficientPointsModalCheck = true;
    this.appSettings.defaultAirlineSearchCode = "";
    this.appSettings.reloadOnSearch = true;
    this.appSettings.reloadOnQuickSearch = true;

    this.$rootScope.selectedCurrency = this.$rootScope.currenciesService.findByCode("AUD");
    this.$rootScope.globalState.productType = this.kaligoConfig.PRODUCT_TYPE;
    this.$rootScope.globalState.specialRequestText = "wl.vma.special_request_text";
    this.$rootScope.globalState.serviceEmail = "support@loyalty.virginmoney.com.au";
    this.$rootScope.globalState.showTotalNights = true;
    this.appSettings.pointsCashSliderSettings.sliderLeftLabel = "txt.cash";
    this.appSettings.pointsCashSliderSettings.sliderRightLabel = "Points";
    this.appSettings.stripePublishableKey = {
      production: "pk_live_cX7OHHaQlSdUvzoiHAggzbvi00YFCR5XLZ",
      test: "pk_test_uW9cZE9crEehCKC2ooflwTUg00Lzn2deJn",
    };

    if (this.$rootScope.isUserLoggedIn()) {
      this.hermesService.initHermes();
    }

    this.vmaAdobeAnalyticsService.adobeAnalyticsData = adobeAnalyticsData;
    this.vmaAdobeAnalyticsService.initiateAnalyticsOnRouteChange();
    // To avoid blank overlay appear in iOS VMA mobile app when page load
    if (this.userAgentChecker.isIOS()) {
      this.$window.scrollTo(0, 0);
    }
    this.$scope.globalStateService = this.globalStateService;
    this.appSettings.roomGuestParameters.labelOnlySummaryText = true;
    this.appSettings.showCancelPolicyOnRedeem = true;
    this.appSettings.allowCancelRedemptionBooking = true;
    this.appSettings.payWithPoints.roundToNearest = 1;
    this.appSettings.maxEarnOnRedeemPoints = true;
    this.appSettings.showCashBeforePoints = true;
    this.appSettings.isTravelBreakdownFlexDirectionDefault = false;
    this.appSettings.customFlightsTimeLabel = "hh:mm A";
    this.appSettings.customFlightsDateTimeLabel = "ddd, DD MMM YYYY";
    this.appSettings.enableUserActionTracking = true;
    this.appSettings.pointsCashSliderSettings.pointSliderDefaultValue = 16;
    this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider = true;
    this.appSettings.sortSettings.canUseFullPointsOnRedemption = true;
    this.appSettings.sortSettings.canUseFullCashOnRedemption = true;
    this.appSettings.hideRedemptionRangeSlider = true;
    this.appSettings.customFlightsSearchErrorMessage.origin = "Please fill in origin";
    this.appSettings.stripePaymentIntentsEnabled = true;
    this.searchSortingService.initializePermittedSortingOptions([
      SortType.DEAL, SortType.BEST_RATED, SortType.DISTANCE_LOHI, SortType.PAY_POINTS_CASH_HILO,
      SortType.PAY_POINTS_CASH_LOHI, SortType.RATING_LOHI, SortType.RATING_HILO,
    ]);
    this.appSettings.mastheadBannerSettings.landing_page_banner.hotels = "november-summer";
    this.appSettings.requireLoginOnConfirmationPage = true;
    this.appSettings.departuresOnly = true;
    this.appSettings.mastheadBannerSettings.landing_page_banner.flights = "travel";
    this.$rootScope.globalState.requireLoginOnCheckout = true;
  }

}

angular.module("BookingApp").controller("VmaController", VmaController);
