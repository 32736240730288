import { AdobeAnalyticsService } from "booking_app/services/adobe-analytics.service";
import { PageDiscoveryService } from "booking_app/services/page-discovery.service";
import { AppSettings } from "booking_app/values/app-settings";
import { UserAgentChecker } from "booking_app/utils/user-agent-checker";

export class VmaAdobeAnalyticsService extends AdobeAnalyticsService {

  constructor(
    $rootScope: any,
    $window: any,
    pageDiscoveryService: PageDiscoveryService,
    appSettings: AppSettings,
    userAgentChecker: UserAgentChecker,
  ) {
    super(
      $rootScope,
      $window,
      pageDiscoveryService,
      appSettings,
      userAgentChecker,
    );
  }

}

angular.module("BookingApp").service("VmaAdobeAnalyticsService", VmaAdobeAnalyticsService);
